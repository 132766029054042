import * as React from "react"
import { Link } from "gatsby"
import Button from "./Button"
import { SupportArticlesT } from "types/Common"
import SupportArticlesList from "components/molecules/SupportArticlesList"
import { useTranslation } from "react-i18next"

type Props = {
  backButtonLink: string
  backButtonTitle: string
  relatedArticles?: SupportArticlesT
}

const SupportContact: React.ComponentType<Props> = ({
  backButtonLink,
  backButtonTitle,
  relatedArticles,
}) => {
  const { t, i18n } = useTranslation()
  const stillMissingInfo = t("stillMissingInfo")
  const contactUs = t("contactUs")
  const relatedArticlesTitle = t("relatedArticles")
  return (
    <div className={"border-t"}>
      <div className={"mt-3 sm:text-right text-center text-lg"}>
        <span className={"sm:inline block sm:mr-1 mr-0"}>
          {stillMissingInfo}
        </span>
        <Link
          className={"text-quantcomDarkBlue hover:text-quantcomBlue"}
          to={i18n.language === "en" ? "/en/contact" : "/kontakt"}
        >
          {contactUs}
        </Link>
      </div>
      {relatedArticles && (
        <SupportArticlesList
          articles={relatedArticles}
          categoryTitle={relatedArticlesTitle}
        />
      )}
      <div className={"text-center"}>
        <Button
          iconAnimated
          link={backButtonLink}
          className="mt-10"
          iconLeft={"arrow-left-short"}
        >
          {backButtonTitle}
        </Button>
      </div>
    </div>
  )
}

export default SupportContact
