import * as React from "react"
import Button from "./Button"
import { SupportArticleSectionT } from "types/Common"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

type Props = {
  sections: SupportArticleSectionT[]
  executeScroll: (id: number) => void
  isSticky: boolean
  visibleOnMobile: boolean
}

const SupportNavigationSidebar: React.ComponentType<Props> = ({
  sections,
  executeScroll,
  isSticky,
  visibleOnMobile,
}) => {
  const { t } = useTranslation()
  const pageTop = t("pageTop")

  const dictionaryLeftPanel = {
    "overflow-y": "auto",
    "height": "75vh"
  }

  return (
    <div
      className={classNames(
        "bg-gray-100 sm:pb-8 sm:pt-0 pt-2 pb-2 top-2 sm:mb-0 mb-4",
        {
          sticky: isSticky,
          "sm:hidden block": visibleOnMobile,
          "sm:block hidden": !visibleOnMobile,
        }
      )}
      style={dictionaryLeftPanel}
    >
      <div className={"mb-4 sm:block hidden"}>
        <Button
          className="-mt-1"
          iconLeft="arrow-top"
          iconAnimated
          type="iconAnimatedTop"
          onClick={() => executeScroll(0)}
        >
          {pageTop}
        </Button>
      </div>
      {sections.map(section => {
        return (
          <div
            className={
              "cursor-pointer transition-colors duration-150 sm:mx-4 px-4 sm:my-6 border-l-4 text-quantcomDarkBlue hover:text-quantcomBlue hover:border-quantcomBlue border-transparent"
            }
            onClick={() => executeScroll(section.id)}
            key={section.id}
          >
            {section.title}
          </div>
        )
      })}
    </div>
  )
}

export default SupportNavigationSidebar
