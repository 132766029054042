import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "styles/variables"

const Wrapper = styled.div`
  line-height: 1.8;
  ${mediaQueries.smMin} {
    font-size: 1.125rem;
  }

  p {
    margin-bottom: 1.5rem;
    ${mediaQueries.mdMax} {
      text-align: center;
    }
  }

  a {
    color: ${colors.primary};
    text-decoration: underline;
  }

  h2 {
    margin: 2em 0 1.2em;
    font-weight: bold;
    font-size: 2.25rem;
    color: ${colors.primary};
  }

  h3 {
    margin: 1.5em 0 1em;
    font-weight: bold;
    font-size: 1.8em;
  }

  h4 {
    margin: 1em 0;
    font-weight: bold;
    font-size: 1em;
  }

  ul,
  ol {
    margin: 0 0 1.5em;

    li {
      margin: ${props => (props.addLiMargin ? "0 0 1em" : "0")};
      position: relative;
      padding: 0 0 0 1.34em;
      &::before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        display: block;
        position: absolute;
        top: 0.65em;
        left: 0.1em;
        background: ${colors.primary};
      }
    }
  }

  ol {
    padding: 0 0 0 1.1em;
    list-style: number;

    li {
      padding: 0 0 0 0.24em;
      &::before {
        content: none;
      }
      &::marker {
        color: ${colors.primary};
      }
    }
  }
`

const WysiwygRenderer = ({ content, addLiMargin = true }) => {
  return (
    <Wrapper
      addLiMargin={addLiMargin}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default WysiwygRenderer
