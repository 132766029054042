import * as React from "react"
import SEO from "components/seo"
import { PageContextT, SupportArticleSectionT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import Container from "../components/atoms/Container"
import BlockTitle from "../components/atoms/BlockTitle"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import SupportNavigationSidebar from "../components/atoms/SupportNavigationSidebar"
import { formatDate } from "../utilities/dateTime"
import WysiwygRenderer from "../components/atoms/WysiwygRenderer"
import SupportContact from "../components/atoms/SupportContact"
import { useEffect } from "react"

const Title = styled(BlockTitle)`
  margin-bottom: 0.5em;
`
type Props = {
  pageContext: PageContextT
  location: Location
}

const SupportServices: React.ComponentType<Props> = (props: Props) => {
  const {
    // location,
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      configurations,
      mobileHeaderLinks,
      localizedPage,
      termDictionary
    },
  } = props

  let sectionRefs = {}
  termDictionary.map(term => {
    sectionRefs[term.id] = React.useRef(null)
  });
  sectionRefs[0] = React.useRef(null)
  const executeScroll = ref => sectionRefs[ref].current.scrollIntoView()

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParameters = new URLSearchParams(location.search)
      const termSlug = urlParameters?.get("pojem")
      if (termSlug) {
        const selectedTermSlug = termDictionary
          .filter(term => term.Slug === termSlug)
          .at(0)
        if (selectedTermSlug) {
          executeScroll(selectedTermSlug.id)
        }
      }
    }
  })

  const supportArticleSections: SupportArticleSectionT[] = []
  termDictionary.forEach(term => {
    supportArticleSections.push({
      id: term.id,
      text: term.Description,
      title: term.Title
    })
  })

  const { t, i18n } = useTranslation()
  const supportLink = i18n.language === "en" ? "/en/support" : "/podpora"
  const breadcrumbs = [
    {
      title: t("support"),
      route: supportLink,
    },
    {
      title: page.title,
    },
  ]

  return (<Layout
    mainHeaderLinks={mainHeaderLinks}
    otherHeaderLinks={otherHeaderLinks}
    navigationLinks={navigationLinks}
    footerSections={footerSections}
    socialLinks={socialLinks}
    commonLinks={commonLinks}
    breadcrumbs={breadcrumbs}
    configurations={configurations}
    mobileHeaderLinks={mobileHeaderLinks}
    breadCrumbs={["podpora", "support"]}
    localizedPage={localizedPage}
  >
    <SEO
      title={page.SEO?.title || page.title}
      description={page.SEO?.description}
    />

    <Container>
      <div className="flex w-full flex-wrap sm:mt-16 mt-8">
        <div className="w-full md:w-4/12 pl-4 pr-0 sm:pl-0 sm:pr-0 mb-4 sm:block hidden">
          <SupportNavigationSidebar
            executeScroll={executeScroll}
            sections={supportArticleSections}
            isSticky={true}
            visibleOnMobile={false}
          />
        </div>
        <div
          className="w-full md:w-8/12 sm:pr-0 pr-2 pl-2 sm:px-8"
          ref={sectionRefs[0]}
        >
          <Title title={page.title} align={"left"} />
          <div className={"sm:mb-8 mb-4"}>
            {t("lastChange")} {formatDate(page.updated_at)}
          </div>
          <SupportNavigationSidebar
            executeScroll={executeScroll}
            sections={supportArticleSections}
            isSticky={false}
            visibleOnMobile={true}
          />

          {termDictionary.map(term => {
            return (
              <div key={term.id} ref={sectionRefs[term.id]}>
                <WysiwygRenderer content={"<h2>" + term.Title + "</h2>"} />
                <WysiwygRenderer content={term.Description} />
              </div>
            )
          })}
        </div>
      </div>
      <div className={"mb-8"}>
        <SupportContact
          backButtonLink={supportLink}
          backButtonTitle={t("backToSupport")}
        />
      </div>
    </Container>
  </Layout>)
}

export default SupportServices
