import { rem } from "./rem"
import { css } from "styled-components"

export const allAbs = (distance: number = 0) => `
  position: absolute;
  top: ${rem(distance)};
  right: ${rem(distance)};
  bottom: ${rem(distance)};
  left: ${rem(distance)};
`
