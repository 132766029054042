import { useTranslation } from "react-i18next"

export const formatDate = (value: string) => {
  const { i18n } = useTranslation()
  const language = i18n.language === 'cs'
    ? 'cs-CS'
    : 'en-EN'
  const date = new Date(value)
  return new Intl.DateTimeFormat(language, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date)
}

export const getReadingTime = (perex: String, text: String) => {
  const wpm = 225
  const mainTextWords = text ? text.trim().split(/\s+/).length : 0
  const perexWords = perex ? perex.trim().split(/\s+/).length : 0

  return Math.ceil(mainTextWords / wpm) + Math.ceil(perexWords / wpm)
}
