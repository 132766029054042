import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { allAbs } from "styles/mixins/mixins"
import { colors, mediaQueries } from "styles/variables"
import Icon from "../atoms/Icon"

type PropsT = {
  link: string
  className?: string
  fitting?: boolean
  outline?: boolean
  buttonTitle?: string
  visibleIcon?: boolean
  icon?: string
  tag?: "a"
}

const wrapperDesktop = `
  padding-top: 2rem;
  padding-bottom: 4rem;

  &::before {
    content: "";
    ${allAbs()}
    left: -1.8rem;
    background: transparent;
    box-shadow: 0px 0px 28px #66666600;
    transition: all 0.1s ease;
  }
`

const wrapperHover = `
  border-bottom-color: transparent;
  transition-duration: 0.4s;
  &::before {
    transition-duration: 0.4s;
    background: ${colors.white};
    box-shadow: 0px 0px 28px #6666661f;
  }

  & .box-icon-button {
    transition-duration: 0.4s;
    ${mediaQueries.lgMin} {
          color: ${colors.primary};
    }
    [class^="icon"] {
      text-indent: 0;
      color: ${colors.white};
      background: ${colors.primary};
      transition-duration: 0.4s;
    }
    .box-icon-button-title {
      padding-right: 0.5em;
      transition-duration: 0.4s;
    }
  }
`

const wrapperHoverOutline = `
  &::before {
    border-color: ${colors.primary};
    top: 0;
  }
`

const Wrapper = styled(({ component: Component, ...props }) => (
  <Component {...props} />
))`
  padding: 1.5rem 0;
  position: relative;
  display: block;
  border-bottom: 1px solid ${colors["gray-300"]};
  transition: border 0.1s ease;

  ${mediaQueries.lgMin} {
    ${wrapperDesktop}

    &:hover {
      ${wrapperHover}
    }
  }

  ${({ $fitting }) =>
    $fitting &&
    `
    padding: 0;
    border-bottom: 0;

    ${mediaQueries.mdMin} {
      padding: 0;

      &::before {
        left: 0;
      }
    }
  `}

  ${({ $outline }) =>
    $outline &&
    `
      border-bottom: none;
      &::after {
        content: "";
        ${allAbs()};
        left: -1.8rem;
      }

      &::before {
        border: 2px solid transparent;
        top: 100%;
        box-shadow: none !important;
        background: transparent !important;
      }

      &:hover {
        ${wrapperHoverOutline}
      }

      ${mediaQueries.lgMax} {
        ${wrapperDesktop}
        ${wrapperHover}
        ${wrapperHoverOutline}

        padding-left: 1.5rem;

        &::before {
          left: 0;
        }
      }


  `}
`

const StyledIconButton = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -0.5rem;
  bottom: -0.5rem;
  color: ${colors.secondary};
  transition: color 0.1s ease;

  .box-icon-button-title {
    transition: padding 0.1s;
  }
`

const StyledIcon = styled(Icon)`
  padding: 1.4em;
  font-size: 0.95rem;
  line-height: 1;
  text-indent: -50%;
  display: block;
  background: transparent;
  color: transparent;

  ${({ $visibleIcon }) =>
    $visibleIcon &&
    `
    color: ${colors.secondary};
    text-indent: 0;
  `}
  transition: all 0.1s ease;

  ${({ $buttonTitle }) =>
    $buttonTitle &&
    `
    color: inherit;
  `}
`

const Box: React.FunctionComponent<PropsT> = ({
  children,
  link,
  fitting,
  className,
  buttonTitle,
  outline,
  visibleIcon,
  icon = "arrow-right-short",
  tag,
}) => {
  const component = tag ? tag : Link

  return (
    <Wrapper
      component={component}
      to={link}
      href={link}
      className={className}
      $fitting={fitting}
      $outline={outline}
    >
      <div className="relative">{children}</div>
      <StyledIconButton className="box-icon-button">
        {buttonTitle && (
          <span className="pb-1 box-icon-button-title">{buttonTitle}</span>
        )}
        <StyledIcon
          icon={icon}
          $buttonTitle={buttonTitle}
          $visibleIcon={visibleIcon}
        />
      </StyledIconButton>
    </Wrapper>
  )
}

export default Box
