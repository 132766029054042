import * as React from "react"
import { SupportArticlesT } from "types/Common"
import SupportCategoryTitle from "components/atoms/SupportCategoryTitle"
import Grid from "components/PageBuilder/Common/Grid"
import { ProductDetail } from "components/atoms/ProductDetail"
import { IconsId } from "assets/fonts/iconfont/icons"
import { useTranslation } from "react-i18next"

type Props = {
  articles: SupportArticlesT
  categoryTitle: string
  categoryIcon?: IconsId
}

const SupportArticlesList: React.ComponentType<Props> = ({
  articles,
  categoryTitle,
  categoryIcon,
}) => {
  const { t } = useTranslation()
  if (articles.length > 0) {
    return (
      <div className={"my-10"}>
        <SupportCategoryTitle title={categoryTitle} icon={categoryIcon} />
        <div className={"m-10"}>
          <Grid xs={1} sm={2} lg={3}>
            {articles.map(article => {
              return (
                <ProductDetail
                  key={article.id}
                  link={article.url}
                  buttonTitle={t("findOutMore")}
                  product={article}
                />
              )
            })}
          </Grid>
        </div>
      </div>
    )
  }
  return null
}

export default SupportArticlesList
