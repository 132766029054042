import classNames from "classnames"
import React, { ReactNode } from "react"
import styled from "styled-components"

type Props = {
  ownTemplateColumns?: string
  alignItems?: string
  gap?: string
  gapSm?: string
  children: ReactNode
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  xlg?: number
  className?: string
}

const GridStyled = styled.div`
  display: grid;
  grid-gap: ${props => (props.gap ? props.gap : "5px")};
  align-items: ${props => (props.alignItems ? props.alignItems : "initial")};
  width: 100%;

  ${({ gapSm }) =>
    gapSm &&
    `
    grid-gap: ${gapSm};
  `}}
`

const Grid = ({
  children,
  xs = 1,
  sm = 1,
  md,
  lg,
  xl,
  xlg,
  className,
  ...rest
}: Props) => {
  return (
    <GridStyled
      {...rest}
      className={classNames(
        {
          [`grid-cols-${xs}`]: xs,
          [`sm:grid-cols-${sm}`]: sm,
          [`md:grid-cols-${md}`]: md,
          [`lg:grid-cols-${lg}`]: lg,
          [`xl:grid-cols-${xl}`]: xl,
          [`2xl:grid-cols-${xlg}`]: xlg,
        },
        className
      )}
    >
      {children}
    </GridStyled>
  )
}

export default Grid
