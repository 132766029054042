import * as React from "react"
import { ProductT, SupportArticleT, SupportCategoryT } from "types/Common"
import Icon from "./Icon"
import styled from "styled-components"
import { colors } from "../../styles/variables"
import { IconsId } from "assets/fonts/iconfont/icons"
import Box from "../molecules/Box"

type PropsT = {
  product: ProductT | SupportCategoryT | SupportArticleT
  buttonTitle: string
  iconSize?: string
  link: string
}

const ProductDetailWrapper = styled.div`
  transition: 0.5s ease-in-out;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: calc(100% - 37px);
    bottom: 19px;
    background-color: transparent;
    border: 2px solid ${colors.primary};
    z-index: 0;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: center bottom;
    transition: transform 220ms ease-in;
  }
  @media (max-width: 1024px) {
    .bg {
      transform: scale(1.15);
      opacity: 1;
    }
  }
  &:hover {
    cursor: pointer;
    .bg {
      transform: scale(1.15);
      opacity: 1;
    }
    .iconWrapper {
      background: ${colors.primary};
    }
    .iconArrow {
      color: white;
      left: 0px;
    }
  }
`

export const ProductDetail: React.ComponentType<PropsT> = ({
  product,
  buttonTitle,
  link,
  iconSize = "text-3xl",
}) => {
  const iconName = product.icon?.icon
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .toLowerCase() as IconsId

  return (
    <Box link={link} outline buttonTitle={buttonTitle}>
      <div className={"flex flex-col relative group sm:mb-0 mb-10"}>
        {product.icon && (
          <Icon icon={iconName} color={"text-quantcomBlue"} size={iconSize} />
        )}
        <h3 className={"mt-3 mb-5 text-quantcomDarkBlue font-black text-2xl"}>
          {product.name}
        </h3>
        <div
          className={"text-lg xl:pr-0 pr-10 sm:w-11/12 w-full flex-grow"}
          dangerouslySetInnerHTML={{
            __html: product.description,
          }}
        />
      </div>
    </Box>
  )
}
